import { Dialog, DialogActions, DialogContent, rgbToHex } from '@mui/material'
import React, { useContext, useEffect, useState, Resolution, Margin, Options } from 'react'
import generatePDF, { usePDF } from 'react-to-pdf';
import { AuthContext } from '../context/AuthContext';
//import imagenDashboard from '../assets/22.jpg'
import { Height } from '@mui/icons-material';
import { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, Image } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { ObtenerDatos } from '../helpers/ApiHelper';

const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: 'white',
      width:'100%',
      alignItems:'flex-start'
    },
    section: {
      margin: 10,
      padding: 10,
  //    flexGrow: 1,
      width:'100%'
    },
  });

export const ModalFichaRub = () => {
    const {modalFichaRub, setModalFichaRub, beneficiarioSeleccionado} = useContext(AuthContext)
const [imageBuffer, setImageBuffer] = useState(null)
const [apoyos, setApoyos] = useState()
    const obtenerImagen = async() => {
      await fetch('https://rubjrz.com/imagenes/logo.png').then(response => {
        setImageBuffer(response.body)
      })
    }

    const obtenerApoyos = () => {
        const data = new FormData();
        data.append('idBeneficiario', beneficiarioSeleccionado?.idBeneficiario)
        ObtenerDatos('/apoyos.php?funcion=apoyosBeneficiario', data).then(response => {
            console.log('Respuesta de apoyos', response)
            setApoyos(response)
        })
    }

      useEffect(() => {
        if(beneficiarioSeleccionado?.idBeneficiario) obtenerApoyos()
        
      }, [beneficiarioSeleccionado])
      

      const handleClose = () => {
        setModalFichaRub(false)
      }

    
      const MyDocument = () => (
        <Document
          style={{
            width:'100%'
          }}
        >
          <Page size="letter">
            <View
              style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'flex-start',
                backgroundColor:'white',
                flex:30
              }}
            >
              <Image
                // cache
                //  width={200}
                //  height='auto'
    //              src={imagenDashboard}
                  src={{ uri: beneficiarioSeleccionado?.foto, method: 'GET', headers: {}, body: '', credentials: 'include' }}
                  style={{ width: 125, marginTop:30, marginLeft:20, objectFit:'contain', borderRadius:20}}
                />
                <View
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    backgroundColor:'white',
                    flex:1,
                    margin:30
                  }}
                >
                  <View
                    style={{
                      display:'flex',
                      flexDirection:'row',
                    }}
                  >
                    <View
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:30,
                        backgroundColor:'#a09b9b',
                        padding:5,
                        borderTopLeftRadius:15,
                        borderBottomLeftRadius:15
                      }}
                    >
                      <Text
                        style={{
                          fontSize:12,
                          fontWeight:'600'
  
                        }}
                      >
                        Nombre
                      </Text>
                    </View>
                    <View
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:70,
                        padding:5,
                        backgroundColor:'#f2f2f2'
                      }}
                    >
                      <Text
                        style={{
                          fontSize:12,
                          fontWeight:'400'
  
                        }}                      
                      >
                      {beneficiarioSeleccionado?.apellidoPaterno+' '+beneficiarioSeleccionado?.apellidoMaterno+' '+beneficiarioSeleccionado?.nombre1+' '+beneficiarioSeleccionado?.nombre2}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      marginTop:5,
                      //alignItems:'center'
                    }}
                  >
                    <View
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:30,
                        backgroundColor:'#a09b9b',
                        padding:5,
                        borderTopLeftRadius:15,
                        borderBottomLeftRadius:15
                      }}
                    >
                      <Text
                        style={{
                          fontSize:12,
                          fontWeight:'600'
  
                        }}
                      >
                        Edad y Fecha de Nacimiento
                      </Text>
                    </View>
                    <View
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:70,
                        padding:5,
                        backgroundColor:'#f2f2f2'
                      }}
                    >
                      <Text
                        style={{
                          fontSize:12,
                          fontWeight:'400'
  
                        }}                      
                      >
                      {dayjs(beneficiarioSeleccionado?.fechaNacimiento).format('DD/MM/YYYY')+' - '+dayjs().diff(beneficiarioSeleccionado?.fechaNacimiento,'year')} años
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      marginTop:5
                    }}
                  >
                    <View
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:30,
                        backgroundColor:'#a09b9b',
                        padding:5,
                        borderTopLeftRadius:15,
                        borderBottomLeftRadius:15,
                      }}
                    >
                      <Text
                        style={{
                          fontSize:12,
                          fontWeight:'600'
  
                        }}
                      >
                        Domicilio
                      </Text>
                    </View>
                    <View
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:70,
                        padding:5,
                        backgroundColor:'#f2f2f2'
                      }}
                    >
                      <Text
                        style={{
                          fontSize:12,
                          fontWeight:'400'
  
                        }}                      
                      >
                      {beneficiarioSeleccionado?.direccion+', '+beneficiarioSeleccionado?.colonia}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      marginTop:5
                    }}
                  >
                    <View
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:30,
                        backgroundColor:'#a09b9b',
                        padding:5,
                        borderTopLeftRadius:15,
                        borderBottomLeftRadius:15
                      }}
                    >
                      <Text
                        style={{
                          fontSize:12,
                          fontWeight:'600'
  
                        }}
                      >
                        Teléfono 
                      </Text>
                    </View>
                    <View
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:70,
                        padding:5,
                        backgroundColor:'#f2f2f2'
                      }}
                    >
                      <Text
                        style={{
                          fontSize:12,
                          fontWeight:'400'
  
                        }}                      
                      >
                      {beneficiarioSeleccionado?.telefono}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      marginTop:5
                    }}
                  >
                    <View
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:30,
                        backgroundColor:'#a09b9b',
                        padding:5,
                        borderTopLeftRadius:15,
                        borderBottomLeftRadius:15
                      }}
                    >
                      <Text
                        style={{
                          fontSize:12,
                          fontWeight:'600'
  
                        }}
                      >
                        CURP
                      </Text>
                    </View>
                    <View
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:70,
                        padding:5,
                        backgroundColor:'#f2f2f2'
                      }}
                    >
                      <Text
                        style={{
                          fontSize:12,
                          fontWeight:'400'
  
                        }}                      
                      >
                      {beneficiarioSeleccionado?.curp}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      marginTop:5
                    }}
                  >
                    <View
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:30,
                        backgroundColor:'#a09b9b',
                        padding:5,
                        borderTopLeftRadius:15,
                        borderBottomLeftRadius:15
                      }}
                    >
                      <Text
                        style={{
                          fontSize:12,
                          fontWeight:'600'
  
                        }}
                      >
                        ID RUB
                      </Text>
                    </View>
                    <View
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:70,
                        padding:5,
                        backgroundColor:'#f2f2f2'
                      }}
                    >
                      <Text
                        style={{
                          fontSize:12,
                          fontWeight:'400'
  
                        }}                      
                      >
                      {beneficiarioSeleccionado?.idBeneficiario}
                      </Text>
                    </View>
                  </View>
                </View>
            </View>
            <View
              style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'flex-start',
                backgroundColor:'white',
                flex:80
              }}
            >
              <View
                style={{
                  display:'flex',
                  flexDirection:'column',
                  margin:20,
                  flex:1
                }}
              >
                <View
                  style={{
                    display:'flex',
                    flexDirection:'row',
                    maxHeight:30,
                    width:'100%',
               //     flex:1,
                    backgroundColor:'#a09b9b',
                    justifyContent:'center',
                    maxHeight:30,
                    padding:5,
                    borderTopLeftRadius:15,
                    borderTopRightRadius:15
                  }}
                >
                  <Text
                    style={{
                      fontSize:14,
                      fontWeight:'600',
                    }}
                  >
                    Apoyos Recibidos
                  </Text>
                </View>
                <View
                    style={{
                        display:'flex',
                        flexDirection:'column'
                    }}
                >
                            <View
                                style={{
                                    display:'flex',
                                    flexDirection:'row',
                                    minWidth:'100%'
 //                                   flex:1
                                }}
                            >   
                                <Text style={{display:'flex', flex:10, width:80, minWidth:60, fontSize:12}}>Fecha</Text>
                                <Text style={{display:'flex', flex:15, width:80, minWidth:100, fontSize:12}}>Dependencia</Text>
                                <Text style={{display:'flex', flex:15, width:80, minWidth:100, fontSize:12}}>Autorizó</Text>
                                <Text style={{display:'flex', flex:15, width:80, minWidth:120, fontSize:12}}>Apoyo</Text>
                                <Text style={{display:'flex', flex:60, width:80, minWidth:180, fontSize:12}}>Comentarios</Text>
                            </View>

                    {
                        apoyos?.map((apoyo, index) => (
                            <View
                                style={{
                                    display:'flex',
                                    flexDirection:'row',
//                                    flex:1
                                }}
                            >   
                                <Text style={{display:'flex', flexDirection:'row', flex:10, fontSize:10, minWidth:60}}>{dayjs(apoyo?.fecha).format('DD/MM/YYYY')}</Text>
                                <Text style={{display:'flex', flexDirection:'row', flex:10, fontSize:10, minWidth:100}}>{apoyo?.dependencia}</Text>
                                <Text style={{display:'flex', flexDirection:'row', flex:15, fontSize:10, minWidth:100}}>{apoyo?.nombreCompleto}</Text>
                                <Text style={{display:'flex', flexDirection:'row', flex:15, fontSize:10, minWidth:120}}>{apoyo?.tipoApoyo}</Text>
                                <Text style={{display:'flex', flexDirection:'row', flex:60, fontSize:10, minWidth:180, backgroundColor:'white'}}>{apoyo?.comentarios}</Text>
                            </View>
                        ))
                    }

                </View>

              </View>
            </View>
          </Page>
        </Document>
      );

      useEffect(() => {
      }, [beneficiarioSeleccionado])
      

  return (
    <Dialog
        maxWidth='xl'
        fullWidth
        open={modalFichaRub}
        onClose={() => {handleClose()}}
        style={{
          //height:800
        }}
    >
        <DialogContent>
        <div
          style={{
            height:800,
            width:800,
            minWidth:900
          }}
        >
            <PDFViewer
              style={{
                width:1024,
                height:'100vh'
              }}
            >
                <MyDocument />
            </PDFViewer>
    </div>
         </DialogContent>
        <DialogActions>

        </DialogActions>
    </Dialog>
  )
}


//render(pdf, `${__dirname}/example.pdf`);
//ReactDOM.render(<App />, document.getElementById('root'));

