import { Button, Checkbox, FilledInput, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material'
import { borderRadius } from '@mui/system'
import React, { useContext, useEffect, useState } from 'react'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AuthContext } from '../context/AuthContext';
import './estilo.css';
export const LoginMobile = () => {
    const [usuario, setUsuario] = useState(localStorage.getItem('usuario'));
    const [password, setPassword] = useState(localStorage.getItem('password'));
    const {onLogin, configuracion} = useContext(AuthContext);
    const [showPassword, setShowPassword] = React.useState(false);
    const [sesion, setSesion] = useState(localStorage.getItem('mantenerSesion')=='1' ? true:false)

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    console.log('se abrio login mobile')
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const login =() => {
        if(localStorage?.getItem('mantenerSesion')=='1') {
            localStorage.setItem('usuario', usuario)
            localStorage.setItem('password', password)
        } else {
            localStorage.setItem('usuario', '')
            localStorage.setItem('password', '')
        }
        onLogin(usuario,password);
    }

    useEffect(() => {
        console.log(process.env.REACT_APP_IMAGENES, configuracion?.logo)
        if(localStorage.getItem('mantenerUsuario')==1) {
            setUsuario(localStorage.getItem('usuario'))
            setPassword(localStorage.getItem('password'))
        }
    }, [configuracion])

    const mantenerSesion = () => {
        localStorage.setItem('mantenerSesion', sesion)
    }
    
    useEffect(() => {
        console.log('estado de sesion', sesion)
        if(sesion) {
            localStorage.setItem('mantenerSesion','1')
        } else {
            localStorage.setItem('mantenerSesion','0')
        }
    }, [sesion])

    useEffect(() => {
        console.log(localStorage.getItem('mantenerSesion'))
        console.log(localStorage.getItem('usuario'))
        console.log(localStorage.getItem('password'))
    }, [])
  
  return (
    <div
        style={{
            display:'flex',
            flexDirection:'column',
            flex:1,
            backgroundColor:configuracion?.fondo,
            height:'90vh',
            justifyContent:'flex-start'
        }}
    >
        <div
            style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'center',
                justifyContent:'center'
            }}
        >
            <div
                style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:1,
                //    marginLeft:100,
                //    marginRight:100,
                    backgroundColor:'transparent',
                    justifyContent:'center',
                    padding:10,
                    borderRadius:20
                }}
            >
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            backgroundColor:'#B3282D',
                            borderRadius:20,
                            maxWidth:'100%',
                            paddingTop:20,
                            paddingBottom:20,
                        }}
                    >
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                flex:1,
                                justifyContent:'center',
                                backgroundColor:'transparent',
                                //marginTop:60
                            }}
                        >
                            <Typography
                                style={{
                                    fontSize:18,
                                    color:'white',
                                    fontWeight:'600'
                                }}
                            >
                                Acceder al Panel
                            </Typography>
                        </div>
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'center'
                            }}
                        >
                            <Typography
                                style={{
                                    fontSize:12,
                                    color:'white'
                                }}
                            >
                            Usa tú correo y contraseña proporcionados
                            </Typography>
                        </div>
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'center',
                                marginTop:30
                            }}
                        >
                            <TextField 
                                onChange={(e) => setUsuario(e.target.value)}
                                value={usuario}
                                autoComplete='off'
                                style={{
                                    display:'flex',
                                    flex:1,
                                    maxWidth:'60%',
                                    borderRadius:10, backgroundColor:'white', height:55
                                }}
                                InputProps={{
                                    disableUnderline:true,
                                    style:{
                                        borderRadius:10,
                                        borderColor:'transparent',
                                        
                                    }
                                }}
                             size='small' label="Username" variant="filled" />
                        </div>
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'center',
                                marginTop:30,
                                marginLeft:20
                            }}
                        >
                            <TextField
                                onChange={(e) => setPassword(e.target.value)}
                                onKeyUp={(e) => (e.which==13 ? login():null )}
                                value={password}
                                autoComplete='off'
//                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                style={{
                                    display:'flex',
                                    flex:1,
                                    maxWidth:'62%',
                                    borderRadius:10, backgroundColor:'white', height:55
                                }}
                                InputProps={{
                                    disableUnderline:true,
                                    style:{
                                        borderRadius:10,
                                        borderColor:'transparent',
                                        
                                    }
                                }}
                                size='small' label="Password" variant="filled" 
                            />
                                    <IconButton
                                    style={{color:'white'}}
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                        </div>
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'center',
                                marginTop:5,
                            }}
                        >
                             <FormControlLabel value={sesion} checked={sesion}  control={<Checkbox onChange={(e) => {console.log('cambio checkbox', e.target.checked, sesion); setSesion(e.target.checked)}} style={{color:'white'}}  size='small'  />} label={<Typography style={{fontSize:12, color:'white'}}>Mantener sesión</Typography>} />
                             <Button
                                style={{
                                    textTransform:'unset',
                                    color:'white',
                                }}
                             >¿Olvidaste tu contraseña?</Button>
                        </div>
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'center',
                                marginTop:5,
                            }}
                        >
                             <Button
                             onClick={() => login()}
                                style={{
                                    textTransform:'unset',
                                    backgroundColor:'white',
                                    color:'#B3282D',
                                    minWidth:'60%',
                                    borderRadius:10,
                                    marginTop:20
                                }}
                             >
                                Iniciar sesión
                             </Button>
                        </div>
                    </div>
            </div>
            {/* <div
                style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:1,
                    marginLeft:50,
                    marginRight:50,
                    backgroundColor:'transparent',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    width:'100%',
                    height:'100%',
                    paddingTop:200
                }}
            >
                             <img  
                src={process.env.REACT_APP_IMAGENES+'/'+configuracion?.logo } 
                style={{
                 // width:150,
                //  height:150
                }}
              />
            </div> */}
        </div>
    </div>
  )
}
