import { Autocomplete, Button, Dialog, DialogContent, Divider, IconButton, TextField } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../context/AuthContext';
import { UsuariosContext } from '../context/UsuariosContext';
import Swal from 'sweetalert2';
import { BadgeOutlined, Camera, CameraAlt, ImageSearch, Search } from '@mui/icons-material';
import { ObtenerDatos } from '../helpers/ApiHelper';
import useTiposApoyo from '../hooks/useTiposApoyo';
import useDependencias from '../components/DependenciasComponent';
import Select from 'react-select';
import dayjs from 'dayjs';


export const AgregarApoyo = () => {
    const {modalEntregaApoyo, setModalEntregaApoyo, beneficiarioSeleccionado, setBeneficiarioSeleccionado} = useContext(AuthContext)
    const {opcionMovil, setOpcionMovil} = useContext(AuthContext)
    
    const {tipoApoyoSeleccionado, ComboTiposApoyo, obtenerTiposApoyo} = useTiposApoyo();
    const {dependencias, ComboDependencias, dependenciaSeleccionada} = useDependencias();
    const [autorizan, setAutorizan] = useState();
    const uploadInputRef = useRef(null);
    const [file, setFile] = useState()
    const [archivos, setArchivos] = useState([])
    const [datosApoyo, setDatosApoyo] = useState({
        idDependencia:0,
        idTipoApoyo:0,
        idUsuarioAutoriza:0,
        fechaEntrega:dayjs().format("YYYY-MM-DD HH:mm:ss"),
        comentarios:''
    })
    const [idAutorizo, setIdAutorizo] = useState()
    const [modalBeneficiarios, setModalBeneficiarios] = useState(false)
    const [busqueda, setBusqueda] = useState('')
    const [beneficiarios, setBeneficiarios] = useState()
    const [deshabilitaGuardar, setDeshabilitaGuardar] = useState(true)

    const buscarBeneficiario =() => {
        const data = new FormData();
        data.append('busqueda', busqueda)
        ObtenerDatos('/beneficiarios.php?funcion=buscarBeneficiario', data).then(response => {
          console.log('resultados de la busqueda', response)
          setBeneficiarios(response)
          setModalBeneficiarios(true)
        })
      }

    const handleClose = () => {
        setModalBeneficiarios(false)
    }

    useEffect(() => {
        console.log('selecciono dependencia', dependenciaSeleccionada)
        obtenerTiposApoyo(dependenciaSeleccionada?.value || 0);
      }, [dependenciaSeleccionada])

      const obtenerAutorizan = () => {
        ObtenerDatos('/usuarios.php?funcion=autorizanApoyos', null).then(response => {
            console.log('respuesta del servidor', response)
            let opciones = new Array();
            response?.map(autoriza => {
                opciones.push({value:autoriza?.Usuarios_IdUsuario, label:autoriza?.nombreCompleto})
            })
            setAutorizan(opciones)
        })
      }

      useEffect(() => {
        obtenerAutorizan();
      }, [])

      useEffect(() => {
        if(modalEntregaApoyo) obtenerAutorizan() ;
      }, [modalEntregaApoyo])
      
      const SeleccionImagen = (event) => {
        setFile(event.target.files);
        console.log('selecciono imagen',event);
        var reader = new FileReader();
//        reader.onload = function(){
//            imgMilitante.current.src = reader.result;
 //       };
 //       reader.readAsDataURL(event.target.files[0]);
    };

    useEffect(() => {
        console.log('Archivos seleccionados', file)
     //   console.log('esun arreglo', file[0].name)
        let lista = new Array();
        for(let i=0; i<file?.length;i++) {
            console.log(file[i].name)
            lista.push(file[i].name)
        }
        setArchivos(lista)
    }, [file])

    const actualizaDatos = (campo, valor) => {
        setDatosApoyo({
            ...datosApoyo,
            [campo]:valor
        })
    }

    useEffect(() => {
        actualizaDatos('idDependencia', dependenciaSeleccionada?.value)
    }, [dependenciaSeleccionada])

    useEffect(() => {
        actualizaDatos('idTipoApoyo', tipoApoyoSeleccionado?.value)
    }, [tipoApoyoSeleccionado])

    useEffect(() => {
        actualizaDatos('idUsuarioAutoriza', idAutorizo?.value)
    }, [idAutorizo])
    
    

    useEffect(() => {
        console.log('datos de apoyo', datosApoyo)
        validaDatos()
    }, [datosApoyo])

    useEffect(() => {
        validaDatos()
    }, [beneficiarioSeleccionado])
    
    
    const guardarApoyo = () => {
        const data = new FormData();
        data.append('datos',JSON.stringify(datosApoyo))
        data.append('idBeneficiario', beneficiarioSeleccionado?.idBeneficiario)
        data.append('archivos', uploadInputRef.current?.files?.length || 0)

        for(let x=0;x<uploadInputRef?.current?.files.length;x++) {
            console.log('archivo', uploadInputRef.current?.files[x])
            data.append('archivo'+x, uploadInputRef.current?.files[x]);
        }

        ObtenerDatos('/apoyos.php?funcion=guardarApoyo', data).then(response => {
            console.log('respuesta de guardar apoyo', response)
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Apoyo guardado correctamente",
                showConfirmButton: false,
                timer: 1500
              }).then(
                setOpcionMovil('menu')
              );
            setModalEntregaApoyo(false)
            setDeshabilitaGuardar(false)
            setDatosApoyo({
                idDependencia:0,
                idTipoApoyo:0,
                idUsuarioAutoriza:0,
                fechaEntrega:dayjs().format("YYYY-MM-DD HH:mm:ss"),
                comentarios:''
            })
        })
    }

    const validaDatos = () => {
        if(datosApoyo?.idDependencia>0 && datosApoyo?.idTipoApoyo>0 && datosApoyo?.idUsuarioAutoriza>0 && datosApoyo?.comentarios?.length>0 && beneficiarioSeleccionado?.idBeneficiario) {
            setDeshabilitaGuardar(false)
        } else {
            setDeshabilitaGuardar(true)

        }
    }
    
  return (
    <>
    <div
        style={{
            margin:10,
            maxWidth:'90vw'
        }}
    >
              <div
                style={{
                    display:'flex',
                    flexDirection:'column'
                }}
            >
            <div
                style={{
                    maxHeight:20,
                    marginBottom:30,
                    display:'flex',
                    flexDirection:'row',
                    alignItems:'center',
                    marginTop:20
                }}
            >
                <TextField
                    onChange={(e) => {setBusqueda(e.target.value)}}
                    label="Id Rub, CURP ó nombre de beneficiario"
                    type='text'
                    // multiline
                    // rows={2}
                    fullWidth
                    InputProps={{
                        style:{
                            fontSize:14,
                            borderRadius:15,
                            maxHeight:40
                        }
                    }}
                />
                <IconButton
                    onClick={() => {buscarBeneficiario()}}
                >
                    <Search />
                </IconButton>
            </div>
            {
                beneficiarioSeleccionado?.nombre1 ? (
                    <div
                        style={{
                            marginBottom:10
                        }}
                    >
                        {beneficiarioSeleccionado?.nombre1+' '+beneficiarioSeleccionado?.nombre2+' '+beneficiarioSeleccionado?.apellidoPaterno+' '+beneficiarioSeleccionado?.apellidoMaterno}
                    </div>
                ):null
            }

                <ComboDependencias />
                <div style={{marginTop:10}}>
                    <ComboTiposApoyo />
                </div>
                <Select
                    onChange={(e) => {setIdAutorizo(e)}}
                   // value={tipoApoyoSeleccionado}
                    options={autorizan}
                    placeholder="Autorizó"
                    menuPortalTarget={document.body} 
                    InputLabelProps={{
                        style:{
                            fontSize:11
                        }
                    }}
                    InputProps={{
                        style:{
                            fontSize:11
                        }
                    }}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999, fontSize:11 }),
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? 'grey' : 'green',
                            fontSize:12,
                            minWidth:150,
                            maxHeight:20,
                            borderRadius:10,
                            marginTop:10
                        }),
                    }}
                    style={{
                        width:150,
                        maxHeight:20,
                        marginTop:10,
                        menuPortal: base => ({ ...base, zIndex: 999999, fontSize:11 }),
        
                    }}
                />                
                {/* <TextField 
                    onChange={(e) => {actualizaDatos('fechaEntrega', e.target.value)}}
//                    value={datosBeneficiario?.fechaNacimiento}
                    
                    size='small'
                    label="Fecha de entrega"
                    type='date'
                    required
                    style={{
                        marginTop:10

                    }}
                    InputLabelProps={{
                        shrink:true,
                    }}
                    
                /> */}

                    
            </div>
            <div>
                <TextField
                    onChange={(e) => {actualizaDatos('comentarios', e.target.value)}}
                    label="Comentarios"
                    type='text'
                    multiline
                    rows={2}
                    fullWidth
                    style={{
                        marginTop:10
                    }}
                />
            </div>
            <div
                style={{
                    display:'flex',
                    flexDirection:'row',
                    backgroundColor:'transparent'
                }}
            >
                <div
                    style={{
                        display:'flex',
                        flexDirection:'column'
                    }}
                >

<input name="imgMilitante" onChange={event => SeleccionImagen(event) } ref={uploadInputRef} style={{visibility:'hidden'}} accept='image/*' type="file" capture="camera" />
                <Button
                    style={{
                    textTransform:'unset',
                    //maxWidth:140,
                    minWidth:'90vw',
                    marginTop:0,
                    backgroundColor:'#B3282D',
                    borderRadius:10
                    }}
                    variant="contained"
                    onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                    >Foto de Evidencia <CameraAlt /></Button>
                                    </div>

            </div>
            <div>
                {
                    archivos?.map(archivo => (
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'column',
                                fontSize:12
                            }}
                        >
                            {archivo}
                        </div>
                    ))
                }
            </div>
            <div
                style={{
                    display:'flex',
                    flexDirection:'row',
                    justifyContent:'space-between',
                    marginTop:20,
                    minWidth:'90vw'
                }}
            >
                {/* <Button
                    variant='contained'
                    onClick={() => {setModalEntregaApoyo(false)}}
                    style={{textTransform:'unset', color:'black', minWidth:'40%', backgroundColor:'#B3282D', color:'white', borderRadius:10}}
                >
                    Cancelar    
                </Button> */}
                <Button
                    variant='contained'
                    onClick={() => {setDeshabilitaGuardar(true);guardarApoyo()}}
                    disabled={deshabilitaGuardar}
                    style={{textTransform:'unset', color:'black', minWidth:'90vw', backgroundColor: deshabilitaGuardar ? 'gray':'#B3282D', color:'white', borderRadius:10}}
                >
                    Guardar
                </Button>
            </div>
            <div
                style={{
                    display:'flex',
                    flexDirection:'row',
                    justifyContent:'space-between',
                    marginTop:20,
                    minWidth:'90vw'
                }}
            >
                {/* <Button
                    variant='contained'
                    onClick={() => {setModalEntregaApoyo(false)}}
                    style={{textTransform:'unset', color:'black', minWidth:'40%', backgroundColor:'#B3282D', color:'white', borderRadius:10}}
                >
                    Cancelar    
                </Button> */}
            <Button
                onClick={() => {setOpcionMovil('menu')}}
                variant='contained'
                style={{
                    textTransform:'unset',
                    color:'white',
                    backgroundColor:'#B3282D',
                    marginTop:10
                }}
            >
                Cancelar
            </Button>
            </div>
        </div>
        <Dialog
            maxWidth="xl"
            fullWidth
            open={modalBeneficiarios}
            onClose={handleClose}
            style={{
                width:'90vw',
                minWidth:'90vw'
            }}
        >
            <DialogContent>
                {
                    beneficiarios?.beneficiarios?.map((beneficiario, index) => (
                        <>
                        <div
                            onClick={() => {setBeneficiarioSeleccionado(beneficiario); setModalBeneficiarios(false)}}
                            style={{
                                fontSize:12,
                                marginTop:2,
                                marginBottom:2
                            }}
                        >
                            {beneficiario?.nombre1+' '+beneficiario?.nombre2+' '+beneficiario?.apellidoPaterno+' '+beneficiario?.apellidoMaterno}
                        </div>
                        <Divider />
                        </>
                    ))
                }
            </DialogContent>
        </Dialog>
        </>
  )
}
