import { ObtenerDatos } from "./ApiHelper"

export const ObtenerBeneficiarios = (datos, pagina, orden) => {
    return new Promise((resolve, reject) => {

        const data = new FormData();
        data.append('datos',JSON.stringify(datos))
        data.append('pagina', pagina)
        data.append('orden', orden)
         fetch(process.env.REACT_APP_API_URL+'/beneficiarios.php?funcion=obtenerBeneficiarios', {
            method:'POST',
            body:data
        })
        .then(response => response.json())
        .then(datos => resolve(datos))
    })
    .then((datos) => {
        return datos
    })
    .catch(error => {
        console.warn(error);
    })

}