import React, { useContext } from 'react'
import { AuthContext } from '../context/AuthContext'
import { AgregarBeneficiario } from './AgregarBeneficiario'
import { AgregarApoyo } from './AgregarApoyo'
import { FrenteIdentificacion } from '../components/FrenteIdentificacion'
import { ReversoIdentificacion } from '../components/usuarios/ReversoIdentiificacion'

export const OpcionesMovil = () => {
    const {opcionMovil, setOpcionMovil, configuracion} = useContext(AuthContext)
  return (
    <>
    <div
        style={{
            display:'flex',
            flexDirection:'column',
            flex:1,
 //           minHeight:'100vh',
            marginLeft:30,
            marginRight:30,
            justifyContent:'center',
            backgroundColor:'transparent'
        }}
    >
               {
            opcionMovil=='menu' ? (
                <>
                           <div
                style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:1,
//                    marginLeft:50,
                    marginRight:50,
                    backgroundColor:'transparent',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    paddingTop:50,
                    maxHeight:120,
                    minWidth:'100vw',
                    backgroundColor:'transparent'
                }}
            >
                             <img  
                src={process.env.REACT_APP_IMAGENES+'/'+configuracion?.logo } 
                style={{
                  width:'80vw',
                  height:'auto'
                }}
              />
            </div>
        <div
            onClick={() => {setOpcionMovil('agregar')}}
            style={{
                display:'flex',
                flexDirection:'column',
                flex:50,
                padding:5,
                margin:3,
                maxHeight:100,
                minHeight:60,
                alignItems:'center',
                justifyContent:'center',
                backgroundColor:'#B3282D',
                borderRadius:15,
                color:'white',
                marginTop:50
            }}
        >
            Agregar Beneficiario
        </div>
        <div
            onClick={() => {setOpcionMovil('entregar')}}
            style={{
                display:'flex',
                flexDirection:'column',
                flex:50,
                padding:5,
                margin:3,
                maxHeight:100,
                minHeight:60,
                alignItems:'center',
                justifyContent:'center',
                backgroundColor:'#B3282D',
                borderRadius:15,
                color:'white'
            }}
        >
            Entregar Apoyo
        </div>
        </>
        ):null
    }
    </div>

    <div
        style={{
            display:'flex',
            flexDirection:'row',
            flex:1,
            backgroundColor:'transparent',
            justifyContent:'center'
        }}
    >
        {
            opcionMovil=='agregar' ? (
                <AgregarBeneficiario />
            ):null
        }
        {
            opcionMovil=='entregar' ? (
            
                <AgregarApoyo />
            ):null
        }
        {
            opcionMovil=='frenteId' ? (
            
                <FrenteIdentificacion />
            ):null
        }
        {
            opcionMovil=='reversoId' ? (
            
                <ReversoIdentificacion />
            ):null
        }
 
    </div>
    </>
  )
}
