import React, { Children, createContext } from 'react'
import { useState } from 'react';
import { ObtenerDatos } from '../helpers/ApiHelper';
import Swal from 'sweetalert2';

 export const AuthContext = createContext();

 export const AuthProvider = (props) => {
  const [usuarios, setUsuarios] = useState([]);
  const [usuario, setUsuario] = useState([]);
  const [logueado, setLogueado] = useState(false);
  const [URL, setURL] = useState('https://rubjrz.com/servicios');
const [configuracion, setConfiguracion] = useState();
const [opcionSeleccionada, setOpcionSeleccionada] = useState('dashboard')
  const [beneficiarioSeleccionado, setBeneficiarioSeleccionado] = useState()
  const [modalAgregaBeneficiario, setModalAgregaBeneficiario] = useState(false)
  const [modalEntregaApoyo, setModalEntregaApoyo] = useState(false)
  const [modalApoyosBrindados, setModalApoyosBrindados] = useState(false)
  const [modalImagenesEvidencia, setModalImagenesEvidencia] = useState()
const [modalUsuario, setModalUsuario] = useState(false)
const [modalPermisos, setModalPermisos] = useState(false)
const [modalPassword, setModalPassword] = useState(false)
const [modalFichaRub, setModalFichaRub] = useState(false)
const [opcionMovil, setOpcionMovil] = useState('menu')
const [frenteIdentificacion, setFrenteIdentificacion] = useState()
const [reversoIdentificacion, setReversoIdentificacion] = useState()
const [datosBeneficiario, setDatosBeneficiario] = useState({
        'nombre1':'',
        'nombre2':'',
        'apellidoPaterno':'',
        'apellidoMaterno':'',
        'idGenero1':0,
        'fechaNacimiento':'',
        'direccion':'',
        'colonia':'',
        'telefono':'',
        'curp':''
    })
const [paginaBeneficiarios, setPaginaBeneficiarios] = useState(1)
const [paginasBeneficiarios, setPaginasBeneficiarios] = useState(1)
const [ordenBeneficiarios, setOrdenBeneficiarios] = useState('idRubASC')
  const obtenerConfiguracion = () => {
    ObtenerDatos('/configuracion.php?funcion=obtenerConfiguracion').then(response => {
      setConfiguracion(response);
    })
  }

  const obtenerUsuarios = () =>{
    fetch(URL + '/usuarios.php?funcion=usuarios')
    .then(response => response.json())
    .then(data => {
        console.log(data);
        setUsuarios(data);
    })
    .catch(error =>{
        console.log('error',error);
    })
}

  const onLogin =(usuario,password) =>{
    console.log('URL de apis', process.env.REACT_APP_API_URL)
    fetch(process.env.REACT_APP_API_URL+'/usuarios.php?funcion=login&usuario='+usuario+'&password='+password)
     .then(response =>response.json())
     .then(data=> {
       console.log('Datos obtenidos del api',data);
       if(data?.Usuarios_IdUsuario) {
        setLogueado(true);
        setUsuario(data)
        if(data.IdTipoUsuario!="2") {
//          setIdDependenciaMostrar(data.idDependencia)
        } else {
//          setIdDependenciaMostrar(0);
        }
       }
     })
  }

  
  return(
  <AuthContext.Provider value={{usuario,logueado,setUsuario,setLogueado, onLogin, URL, configuracion,
    setConfiguracion, obtenerConfiguracion, opcionSeleccionada, setOpcionSeleccionada, beneficiarioSeleccionado, setBeneficiarioSeleccionado,
    modalAgregaBeneficiario, setModalAgregaBeneficiario, modalEntregaApoyo, setModalEntregaApoyo, modalApoyosBrindados, setModalApoyosBrindados,
    modalImagenesEvidencia, setModalImagenesEvidencia, modalUsuario, setModalUsuario,
    modalPermisos, setModalPermisos, modalPassword, setModalPassword, modalFichaRub, setModalFichaRub, opcionMovil, setOpcionMovil,
    frenteIdentificacion, setFrenteIdentificacion, datosBeneficiario, setDatosBeneficiario, reversoIdentificacion, setReversoIdentificacion,
    paginaBeneficiarios, setPaginaBeneficiarios, ordenBeneficiarios, setOrdenBeneficiarios
  }}>
    {props.children}
  </AuthContext.Provider>
  )
}

