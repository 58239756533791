import React, { useEffect, useState, PureComponent } from 'react'
import { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, Image } from '@react-pdf/renderer';
import imagenDashboard from '../assets/encabezadoResumen.png'
//import { ReactPDFChart } from 'react-pdf-charts';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, BarChart, Bar, Legend, Tooltip, Label } from 'recharts';
import ReactPDF from 'react-pdf-charts'; // Import the default component
import { ObtenerDatos } from '../helpers/ApiHelper';
import useDependenciasUsuario from '../components/DependenciasUsuario';

export const ResumenPage = () => {
  const [datos, setDatos] = useState()
  const {dependencias, ComboDependencias, dependenciaSeleccionada} = useDependenciasUsuario();

  const data = [
    { name: 'A', uv: '10', pv: '20' },
    { name: 'B', uv: 20, pv: 30 },
    { name: 'C', uv: 30, pv: 40 },
  ];
  
  const obtenerDatos = () => {
    const data = new FormData();
    data.append('idDependencia',dependenciaSeleccionada?.value)
    ObtenerDatos('/dashboard.php?funcion=datosResumen',data).then(response => {
      setDatos(response)
    })
  }

  useEffect(() => {
//    obtenerDatos();
  }, [])
  
  useEffect(() => {
    obtenerDatos();
  }, [dependenciaSeleccionada])
  
  useEffect(() => {
    console.log('datos grafica', datos);
  }, [datos])
  

  const SomeComponent = () => (
    <ReactPDF>
      <LineChart data={datos?.benficiados3 || null} height={600} width={500}>
        <XAxis dataKey="name" />
        <YAxis domain={[0,6]} />
        <CartesianGrid stroke="#eee" strokeDasharray="5" />
        <Line type="monotone" dataKey="uv" stroke="#8884d8" />
        {/* <Line type="monotone" dataKey="pv" stroke="#82ca9d" /> */}
      </LineChart>
    </ReactPDF>
  );

  const CustomLabel = ({ value }) => {
    return (
      <text x={0} y={-10} textAnchor="middle" fill="#000">
        {value}
      </text>
    );
  };

  const CustomXAxisLabel = ({ x, y, value }) => {
    // Split the label into two lines
    const parts = value.split(' '); // Split by space or any delimiter
    const firstLine = parts.slice(0, Math.ceil(parts.length / 2)).join(' ');
    const secondLine = parts.slice(Math.ceil(parts.length / 2)).join(' ');
  
    return (
      <g>
        <text x={x} y={y} textAnchor="middle" fill="#666">
          {firstLine}
        </text>
        <text x={x} y={y + 15} textAnchor="middle" fill="#666"> {/* Adjust the y value for spacing */}
          {secondLine}
        </text>
      </g>
    );
  };

  const customizedGroupTick = (props) => {
    const { index, x, y, payload } = props;
  

    return (
      <g>
        <g>
          <text x={x} y={y}>
           data
          </text>
          <text x={x} y={y }>
          data
          </text>
        </g>
      </g>
    );
  };
  

      const MyDocument = () => (
        <Document
          style={{
            width:'100%'
          }}
        >
          <Page scale={1.0} size="letter">
            <View
              style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'flex-start',
                backgroundColor:'white',
                flex:20
              }}
            >
              <Image
                // cache
                //  width={200}
                //  height='auto'
                  src={imagenDashboard}
    //              src={{ uri: beneficiarioSeleccionado?.foto, method: 'GET', headers: {}, body: '', credentials: 'include' }}
                  style={{ width: '90%', marginTop:30, marginLeft:20, objectFit:'contain', borderRadius:20, backgroundColor:'red'}}
              />
            </View>
            <View
              style={{
                display:'flex',
                flexDirection:'column',
                flex:80,
                backgroundColor:'white'
              }}
            >
              <View
                style={{
                  display:'flex',
                  flexDirection:'row',
                  marginRight:30,
                  justifyContent:'space-between'
              //    padding:30
                }}
              >
                <View
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center',
                    flex:100,
                    backgroundColor:'white',
                    marginLeft:0,
                    minWidth:300
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      marginLeft:15,
                      marginBottom:5,maxHeight:30
                    }}
                  >
                    Total Beneficiados
                  </Text>
                  <Text
                    fill='#a21622'
                    style={{
                      fontSize: 25,
                      marginLeft:15,
                      marginBottom:20,maxHeight:30, color:'#a21622'
                    }}
                  >
                    {datos?.beneficiados3?.reduce((acc, current) => acc+parseInt(current.uv), 0)}
                  </Text>
                  <ReactPDF style={{backgroundColor:'white'}}>
                    <LineChart isAnimationActive={false} data={datos?.beneficiados3 || null} height={120} width={280}>
                      <XAxis dataKey="name" />
                      <YAxis />
                      <CartesianGrid stroke="#eee"  />
                      <Line type="monotone" dataKey="uv" stroke="#8884d8" >
                        {datos?.beneficiados3?.map((entry, index) => (
                          <Label key={`label-${index}`} position="top" content={<CustomLabel value={entry.uv} />} />
                        ))}
                      </Line>
                      
                      {/* <Line type="monotone" dataKey="pv" stroke="#82ca9d" /> */}
                    </LineChart>
                  </ReactPDF>
                  <Text
                    style={{
                      fontSize: 12,
                      marginLeft:10,
                      marginBottom:5,maxHeight:30
                     }}
                  >
                    Beneficiados últimos tres meses
                  </Text>

                </View>
                <View
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    flex:100,
                    marginLeft:0,
                    backgroundColor:'white',
                    minWidth:300
                  }}
                >
                 <Text
                    style={{
                      fontSize: 12,
                      marginLeft:115,
                      marginBottom:5,maxHeight:30
                     }}
                  >
                    Total Apoyos
                  </Text>
                  <Text
                    style={{
                      fontSize: 25,
                      marginLeft:10,
                      marginBottom:20,maxHeight:30,
                      marginLeft:0,
                      color:'#a21622',
                      backgroundColor:'white',
                      display:'flex',
                      flexDirection:'row',
                      justifyContent:'center',
                      textAlign:'center'
                    }}
                  >
                    {datos?.apoyos3?.reduce((acc, current) => acc+parseInt(current.uv), 0)}
                  </Text>

                  <ReactPDF>
                    <LineChart data={datos?.apoyos3 || null} height={120} width={280}>
                      <XAxis dataKey="name" />
                      <YAxis />
                      <CartesianGrid stroke="#eee" strokeDasharray="5" />
                      <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                      <Line type="monotone" dataKey="pv" stroke="#82ca9d" />
                    </LineChart>
                  </ReactPDF>
                  <Text
                    style={{
                      fontSize: 12,
                      marginLeft:55,
                      marginBottom:5,maxHeight:30
                     }}
                  >
                    Apoyos Entregados últimos tres meses
                  </Text>
                </View>
      </View>
            <View
              style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'flex-start',
                backgroundColor:'white',
                flex:12
              }}
            >
              <View
                style={{
                  display:'flex',
                  flexDirection:'column',
                  alignItems:'center'
                }}
              >
            <Text
              style={{
                fontSize:14,
                maxWidth:200,
                backgroundColor:'white',
                display:'flex',
                flexDirection:'row',
                textAlign:'center',
                justifyContent:'center',
                marginLeft:20,
                marginTop:20
              }}
            >
              Colonias con mayor número de beneficiados
            </Text>

              <ReactPDF>
              <BarChart
          width={280}
          height={120}
          data={datos?.colonias3 || null}
          margin={{
            top: 20,
            right: 30,
            left: 10,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="1" />
          <XAxis 
              tick={{
                fontSize:7
              }}
              
              interval={0}
          dataKey="name" />
          <YAxis
          tickFormatter={(tick) => parseInt(tick, 10)}
            tick={{
              fontSize:9
            }}
          />
          {/* <Legend /> */}
          <Bar isAnimationActive={false} dataKey="total" stackId="a" fill="#B3282D" />
        </BarChart>
          </ReactPDF>
          </View>
          <View
                style={{
                  display:'flex',
                  flexDirection:'column',
                  alignItems:'center'
                }}
              >
            <Text
              style={{
                fontSize:14,
                maxWidth:200,
                backgroundColor:'white',
                display:'flex',
                flexDirection:'row',
                textAlign:'center',
                justifyContent:'center',
                marginLeft:20,
                marginTop:20
              }}
            >
              Colonias con mayor número de apoyos
            </Text>


          <ReactPDF>

              <BarChart
          width={280}
          height={120}
          data={datos?.coloniasApoyos || null}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="1" />
          <XAxis 
              tick={{
                fontSize:7
              }}
              interval={0}
          dataKey="name" />
          <YAxis
          tickFormatter={(tick) => parseInt(tick, 10)}
            tick={{
              fontSize:9
            }}
          />
          {/* <Legend /> */}
          <Bar isAnimationActive={false} dataKey="total" stackId="a" fill="#B3282D" />
        </BarChart>
          </ReactPDF>
          </View>
          </View>
            <View
              style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'flex-start',
                backgroundColor:'white',
                flex:12
              }}
            >
                        <View
                style={{
                  display:'flex',
                  flexDirection:'column',
                  alignItems:'center'
                }}
              >
            <Text
              style={{
                fontSize:14,
                maxWidth:350,
                backgroundColor:'white',
                display:'flex',
                flexDirection:'row',
                textAlign:'center',
                justifyContent:'center',
                marginLeft:20,
                marginTop:20,
                maxWidth:250
              }}
            >
              Dependencias con mayor número de beneficiados
            </Text>
              <ReactPDF>
              <BarChart
          width={280}
          height={120}
          data={datos?.dependenciasBeneficiados || null}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="1" />
          <XAxis 
            height={30}
            
            angle={0}
              tick={{
                fontSize:6,
                height:40,
                width:400
              }}
              interval={0}
          dataKey="name" />
          <YAxis
            tick={{
              fontSize:9
            }}
          >
            <Label  position="bottom" offset={0} />
          </YAxis>
          {/* <Legend /> */}
          <Bar isAnimationActive={false} dataKey="total" stackId="a" fill="#B3282D" />
        </BarChart>
          </ReactPDF>
          </View>
          <View
                style={{
                  display:'flex',
                  flexDirection:'column',
                  alignItems:'center'
                }}
              >
            <Text
              style={{
                fontSize:14,
                maxWidth:350,
                backgroundColor:'white',
                display:'flex',
                flexDirection:'row',
                textAlign:'center',
                justifyContent:'center',
                marginLeft:20,
                marginTop:20,
                maxWidth:210
              }}
            >
              Dependencias con más Apoyos Entregados
            </Text>          
          <ReactPDF>
              <BarChart
          width={280}
          height={120}
          data={datos?.dependenciasApoyos || null}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="1" />
          <XAxis 
              tick={{
                fontSize:7,
                width:400
              }}
              interval={0}
          dataKey="name" />
          <YAxis
            tick={{
              fontSize:9
            }}
          />
          {/* <Legend /> */}
          <Bar isAnimationActive={false} dataKey="total" stackId="a" fill="#B3282D" />
        </BarChart>
          </ReactPDF>
          </View>
          </View>
          <View
              style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'flex-start',
                backgroundColor:'white',
                flex:7
              }}
              >
                <View
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:50,
                    textAlign:'center'
                  }}
                >
                  <Text style={{fontSize:12}}>HISTÓRICO BENEFICIADOS RUB</Text>
                  <Text style={{fontSize:25, color:'#B3282D'}}>{datos?.historicoBeneficiarios}</Text>
                </View>
                <View
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:50,
                    textAlign:'center'
                  }}
                >
                  <Text style={{fontSize:12}}>HISTÓRICO APOYOS ENTREGADOS RUB</Text>
                  <Text style={{fontSize:25, color:'#B3282D'}}>{datos?.historicoApoyos}</Text>
                </View>

              </View>
      </View>
          </Page>
        </Document>
      )


  return (
    <>
    <div
    style={{
      backgroundColor:'transparent',
      minWidth:150,
      //marginLeft:20,
      maxWidth:250,
      marginTop:15
    }}
  >
  <ComboDependencias />
  </div>

    <div
    style={{
      height:800,
      width:800,
      minWidth:900
    }}
  >
    
      <PDFViewer
        defaultScale={2.0}
        style={{
          width:'80vw',
          height:'96vh',
          marginTop:10
        }}
      >
          <MyDocument />
      </PDFViewer>
</div>
</>
  )
}
