import React, { useContext, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { Button, Typography } from '@mui/material';
import { BackHand, Camera, Check, KeyboardReturn } from '@mui/icons-material';
import ine from '../../assets/ineReverso.png'
import { AuthContext } from '../../context/AuthContext';
const FACING_MODE_USER = { exact: "user" };
const FACING_MODE_ENVIRONMENT = { exact: "environment" };

const videoConstraints = {
  facingMode: FACING_MODE_ENVIRONMENT
};

export const ReversoIdentificacion = () => {
    const {reversoIdentificacion, setReversoIdentificacion, setOpcionMovil} = useContext(AuthContext)
    const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [mask, setMask] = useState([]);
  //const [videoConstraints, setVideoConstraints] = useState({ facingMode: { exact: 'environment' } });
  const [facingMode, setFacingMode] = useState(FACING_MODE_ENVIRONMENT)
  
  const capture = () => {
    // const imageSrc = webcamRef.current.getScreenshot();

    // alert('foto tomada')
    // setImageSrc(imageSrc);
    // setFrenteIdentificacion(imageSrc)

    const video = webcamRef.current.video;

    // Create a canvas to draw the visible area
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    // Set the canvas dimensions to match the visible area of the webcam
    canvas.width = video.videoWidth; // or set to a specific width
    canvas.height = video.videoHeight; // or set to a specific height

    canvas.width = 510; // or set to a specific width
    canvas.height = 300; // or set to a specific height

    // Draw the current frame from the video onto the canvas
//    context.drawImage(video, 0, 100, canvas.width, canvas.height);
    context.drawImage(video, 0, 175, 520, 300, 0, 0, 520, 300);

    // Get the screenshot from the canvas
    const imageSrc1 = canvas.toDataURL('image/jpeg');
    setImageSrc(imageSrc1)
    setReversoIdentificacion(imageSrc1)
  };

  const startDrawing = (e) => {
    setIsDrawing(true);
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setMask([{ x, y }]);
  };

  const draw = (e) => {
    if (!isDrawing) return;
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setMask((prev) => [...prev, { x, y }]);
  };

  const stopDrawing = () => {
    setIsDrawing(false);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.beginPath();
    mask.forEach((point, index) => {
      if (index === 0) {
        ctx.moveTo(point.x, point.y);
      } else {
        ctx.lineTo(point.x, point.y);
      }
    });
    ctx.closePath();
    ctx.clip();
    ctx.drawImage(webcamRef.current.getCanvas(), 0, 0);
  };

//   const switchCamera = () => {
//     setVideoConstraints((prevConstraints) => 
//         prevConstraints.facingMode === 'user' ? { facingMode: { exact: 'environment' } } : { facingMode: 'user' }
//     );
// };

  return (
    <div
        style={{
            display:'flex',
            flexDirection:'column',
            flex:1,
            backgroundColor:'#f3f3f3',
            minWidth:'100vw',
            minHeight:'100vh',
            justifyContent:'center',
          //  opacity:.4
        }}
    >
        <div
            style={{
                width:'100vw',
                height:'30vh',
 //               backgroundColor:'red',
                display:'flex',
                flexDirection:'row',
                justifyContent:'center',
                borderRadius:10,
            //    backgroundColor:'red',
            //    opacity:.5
            }}
        >
            <div
                style={{
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center'
                }}
            >
            <Typography
                style={{
                    marginLeft:'20px',
                    marginRight:'20px',
                    color:'#B3282D',
                    fontWeight:'500',
                    textAlign:'center'
                }}
            >Coloca la identficación como se muestra en la imagen, cuando este dentro del recuadro presiona el boton "Tomar foto", si es correcta presiona "Listo" para regresar a la pantalla de captura</Typography>
              <img src={ine} alt="Captured" 
                style={{ 
                    width:  '30vw', 
                    height:'10vh' ,
                    marginTop:'20px'
                    
                }} 
            />
            </div>
</div>
        <div
            style={{
                width:'100vw',
                height:'30vh',
 //               backgroundColor:'red',
                display:'flex',
                flexDirection:'row',
                justifyContent:'center',
                borderRadius:10,
            //    backgroundColor:'red',
            //    opacity:.5
            }}
        >
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        // width={'100vw'}
        // height={240}
         style={{
             width:'90vw',
             height:'30vh',
//             backgroundColor:'yellow',
             objectFit:'cover',
             borderRadius:10,
             display: reversoIdentificacion?.length>0 ? 'none':'unset'
         }}
        videoConstraints={{
            ...videoConstraints,
            facingMode
          }}
      />
      <img src={imageSrc} alt="Captured" 
        style={{ 
            width:  '90vw', 
            height:'30vh' ,
            display: reversoIdentificacion?.length>0 ? 'unset':'none'
        }} />
      </div>
      <div
        style={{
            marginTop:50,
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-between',
            margin:20
        }}
      >
        <canvas ref={canvasRef} style={{ display: 'none' }} />
        {
            reversoIdentificacion?.length>0 ? (
            <Button
                variant='contained'
                onClick={() => {setReversoIdentificacion()}}
                style={{
                    borderRadius:15,
                    backgroundColor:'#B3282D',
                    textTransform:'unset',
                    width:130
                }}
                >
                  Cambiar foto <Camera />
            </Button>
        ): (
            <Button
            variant='contained'
            onClick={capture}
            style={{
                    borderRadius:15,
                    backgroundColor:'#B3282D',
                    textTransform:'unset',
                    width:130
                }}
            >
                Tomar foto  <Camera />
            </Button>

        )
    }
            <Button
                variant='contained'
                onClick={() => {setOpcionMovil('agregar')}}
                style={{
                    borderRadius:15,
                    backgroundColor:'#B3282D',
                    textTransform:'unset',
                    width:130
                }}
            >
                Listo  <Check />
            </Button>
          {/* <button onClick={capture}>Capture</button>
          <button onClick={() => {setOpcionMovil('agregar')}}>Listo</button> */}
      </div>
      <div
        style={{
            marginTop:50,
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            margin:20
        }}
      >
            <Button
                variant='contained'
                onClick={() => {setOpcionMovil('agregar')}}
                style={{
                    borderRadius:15,
                    backgroundColor:'#B3282D',
                    textTransform:'unset',
                    width:130
                }}
            >
                Regresar  <KeyboardReturn />
            </Button>
          {/* <button onClick={capture}>Capture</button>
          <button onClick={() => {setOpcionMovil('agregar')}}>Listo</button> */}
      </div>
      {/* <button onClick={switchCamera}>Switch Camera</button> */}
    </div>
  );
};

