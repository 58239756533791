import { Delete, Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useContext, useEffect } from 'react'
import { UsuariosContext } from '../../context/UsuariosContext'
import { AuthContext } from '../../context/AuthContext';

export const TablaUsuarios = ({usuarios, obtenerUsuarios, usuarioSeleccionado, setUsuarioSeleccionado, setModalUsuario, eliminarUsuario}) => {
    const {usuario} = useContext(AuthContext)
    console.log('usuario en tabla de beneficiarios', usuario)
    useEffect(() => {
        console.log('usuarios cambio en tabla', usuarios)
    }, [usuarios])
    

  return (
    <div
        style={{
            display:'flex',
            flexDirection:'column',
            marginBottom:60,
            overflowY:'auto',
            backgroundColor:'transparent',
            maxHeight:'75vh'

        }}
    >
        <div
            style={{
                display:'flex',
                flexDirection:'row',
                backgroundColor:'#F7F7F7',
                padding:10,
                borderRadius:10,
                color:'black',
                fontWeight:'600',
                
            }}
        >
            <div style={{display:'flex', flexDirection:'row', flex:20}}>Nombre</div>
            <div style={{display:'flex', flexDirection:'row', flex:20}}>A. Paterno</div>
            <div style={{display:'flex', flexDirection:'row', flex:20}}>A. Materno</div>
            {/* <div style={{display:'flex', flexDirection:'row', flex:20}}>Clave de Elector</div> */}
            <div style={{display:'flex', flexDirection:'row', flex:5}}></div>
            <div style={{display:'flex', flexDirection:'row', flex:5}}></div>
        </div>
        {
            usuarios?.map(usuario => (
                <div
                    onClick={() => {setUsuarioSeleccionado(usuario)}}
                    style={{
                        display:'flex',
                        flexDirection:'row',
                        backgroundColor:usuario.Usuarios_IdUsuario==usuarioSeleccionado?.Usuarios_IdUsuario ? '#B3282D':'white',
                        padding:5,
                        borderRadius:10,
                        color:usuario.Usuarios_IdUsuario==usuarioSeleccionado?.Usuarios_IdUsuario ? 'white':'black',
                        fontSize:12,
                        fontWeight:'300',
                        cursor:'pointer',
                        alignItems:'center',
                              marginTop:5
                    }}
                >
                <div style={{display:'flex', flexDirection:'row', flex:20}}>{usuario.Usuarios_Nombre}</div>
                <div style={{display:'flex', flexDirection:'row', flex:20}}>{usuario.Usuarios_ApellidoPaterno}</div>
                <div style={{display:'flex', flexDirection:'row', flex:20}}>{usuario.Usuarios_ApellidoMaterno}</div>
                 <div style={{display:'flex', flexDirection:'row', flex:5}}>
                    <IconButton
                        onClick={() => {setUsuarioSeleccionado(usuario); setModalUsuario(true)}}
                        size='small'
                    >
                        <Edit style={{color:'green'}} />
                    </IconButton>
                </div>
                <div style={{display:'flex', flexDirection:'row', flex:5}}>
                    
                        <IconButton
                            onClick={() => {setUsuarioSeleccionado(usuario); eliminarUsuario(usuario?.idUsuario)}}
                            size='small'
                        >
                            <Delete style={{color:'red'}} />
                        </IconButton>
                </div>
            </div>    
            ))
        }
    </div>
  )
}
